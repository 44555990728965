import variables from '../../scss/_variables.scss';

const ANIMATION_DELAY = parseFloat(variables.ANIMATION_DELAY.slice(0, -1)) * 1000; // delay for when animation is done
const $document = $(document);
const $window = $(window);
const $html = $(document.documentElement);
const $body = $(document.body);

export {
    $document,
    $window,
    $html,
    $body,
    ANIMATION_DELAY,
};
