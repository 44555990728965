// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"ANIMATION_DELAY": "0.5s",
	"mq-xs": "0",
	"mq-xsl": "544px",
	"mq-sm": "768px",
	"mq-md": "992px",
	"mq-lg": "1200px",
	"mq-xl": "1550px",
	"mq-xxl": "1800px",
	"headerHeight-xs": "80",
	"headerHeight-sm": "90",
	"headerHeight-lg": "100",
	"scrolledHeaderHeight-xs": "40",
	"scrolledHeaderHeight-md": "80",
	"scrolledHeaderHeight-xl": "100"
};
module.exports = exports;
