import { $html } from './utils/environment';

// namings
const NAME = 'App';

class App {
    /**
     * Init the app
     *
     * @param firstBlood: is needed for pages with site transition
     *                    so we do not load globals multiple times
     */
    init (firstBlood) {
        if (firstBlood)
        {
            this.globals();
        }

        // init controllers
        this.import(firstBlood);
    }

    globals () {
        // load transitions
        const transitions = import(/* webpackChunkName: "controllers/Transitions" */ './controllers/TransitionsController');
        transitions.then((e) => {
            const controller = new e.default();
            controller.init();
        }).catch(error => console.error(error));

        // set fullScreen
        const fullscreen = import(/* webpackChunkName: "utils/fullScreen" */ './utils/fullScreen');
        fullscreen.then(e => e.default()).catch(error => console.error(error));

        // set browser
        const browsers = import(/* webpackChunkName: "utils/browsers" */ './utils/browsers');
        browsers.then(e => e.default()).catch(error => console.error(error));
    }

    import () {
        const progressive = import(/* webpackChunkName: "controllers/Progressive" */ './controllers/ProgressiveController');
        const modules = import(/* webpackChunkName: "controllers/Modules" */ './controllers/ModuleController');
        const scroll = import(/* webpackChunkName: "controllers/Scroll" */ './controllers/ScrollController');

        this.loadedControllers = [];

        // always load these controllers
        const controllers = [progressive, modules, scroll];

        // load all controllers
        Promise.all(controllers)
            .then((data) => {
                console.log(`%c${NAME} is done loading`, 'color: yellow;');

                $.each(data, (i, e) => {
                    const controller = new e.default();
                    controller.init();

                    this.loadedControllers.push(controller);
                });

                // flag window as loaded
                $html.addClass('dom-is-loaded').removeClass('dom-is-loading');
            })
            .catch(error => console.error(error));
    }

    /**
     * Reload the app
     *
     * Can be used after an ajax call to reload the DOM
     */
    reinit () {
        console.log(`%c${NAME} has been reloaded`, 'color: yellow;');

        // reinit progressive controller
        this.loadedControllers[0].destroy().init();

        // reinit module controller
        this.loadedControllers[1].destroy().init();

        // reinit scroll controller
        if (this.loadedControllers[2])
        {
            this.loadedControllers[2].destroy().rebuild();
        }
    }
}

// init app
window[NAME] = new App();
window[NAME].init(true);
